import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ctl from "@netlify/classnames-template-literals";
import { BlogItem } from "./blog-item";
import { Text } from "components";

const RelatedArticles = () => {
  const posts = useStaticQuery(graphql`
    {
      allWpPost(sort: { fields: date, order: DESC }, limit: 3) {
        nodes {
          excerpt
          title
          slug
          date(formatString: "LL")
          content
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    quality: 100
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const blogItems = posts.allWpPost.nodes;

  return (
    <section>
      <Text
        variant="h3"
        value="Related Articles"
        color="secondary"
        className="mb-10 text-center"
      />

      <div className={relatedArticleWrap}>
        {blogItems.map(item => (
          <BlogItem key={item.title} item={item} />
        ))}
      </div>
    </section>
  );
};

const relatedArticleWrap = ctl(`
  grid 
  md:grid-cols-3 
  gap-[29px] 
  mb-[152px]
`);

export default RelatedArticles;
