import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import ctl from "@netlify/classnames-template-literals";
import { Container, Text, Layout } from "components";
import RelatedArticles from "../related-articles";

const SinglePost = ({ data }) => {
  const { title, content, date, featuredImage, author } = data.wpPost;

  return (
    <Layout title={`Parkway Blog | ${title}`} theme="light">
      <header className={headerStyle}>
        <Container>
          <div className={wrapStyle}>
            <GatsbyImage
              image={
                featuredImage?.node.localFile.childImageSharp.gatsbyImageData
              }
              alt="blog header image"
              className={imageStyle}
            />

            <div>
              <Text variant="p18" value={date} color="primary-700" />
              <Text
                variant="h3"
                value={title}
                color="secondary"
                className={titleStyle}
              />
              <Text variant="p16" color="primary-700" />
            </div>
          </div>
        </Container>
      </header>

      <Container>
        <section className={articleWrapStyle}>
          <div className={avatarWrap}>
            <StaticImage
              src="../../../../assets/images/svgs/parkway-blog-avatar.svg"
              alt="author's avatar"
              className={adminImageStyle}
            />

            <div>
              <Text variant="h4" value={author.node.name} />
              <Text variant="p14" value="Simple payment infrastructure" />
            </div>
          </div>

          <article className="blog-article">
            <p
              className="max-w-[914px]"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </article>
        </section>

        <RelatedArticles />
      </Container>
    </Layout>
  );
};

const headerStyle = ctl(`
  bg-cover
  bg-[url('../assets/images/single-blog-pattern.png')]
`);
const wrapStyle = ctl(`
  lg:flex
  items-center
  lg:h-auto
  gap-4
  pt-[100px]
  lg:pt-[191px]
  pb-[80px]
`);
const imageStyle = ctl(`
  xl:max-w-[603px]
  lg:max-w-[500px]
  max-w-[100%]
  max-h-[320px]
`);
const titleStyle = ctl(`
  lg:max-w-[588px]
  py-4
`);
const articleWrapStyle = ctl(`
  lg:pt-[146px]
  pt-10
  lg:pb-[185px]
  flex
  flex-col
  lg:flex-row
  gap-6

`);

const avatarWrap = ctl(`
  lg:max-w-[186px] 
  flex 
  items-center
  lg:block 
  gap-[19px]
`);
const adminImageStyle = ctl(`
  lg:w-[124px] 
  lg:h-[124px] 
  w-[90px]
  h-[90px]
  rounded-full 
  lg:mb-5
`);

export default SinglePost;

export const blogQuery = graphql`
  query ($slug: String) {
    wpPost(slug: { eq: $slug }) {
      title
      content
      date(formatString: "LL")
      author {
        node {
          name
          avatar {
            url
          }
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
    }
  }
`;
